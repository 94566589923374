<template>
  <tr>

    <td :data="data.name">
      <input class="get-name-input useral-custom-element-input w-full p-lg"
             @click="showSuggest = true"
             v-model="data.name"/>

      <div class="select-suggest-list" v-if="showSuggest">
        <div class="select-suggest-item"
             v-for="(suggest, suggest_index) in suggestsList"
             :key="suggest_index"
             @click="selectData(suggest)"
             v-if="suggest.show">
          {{ suggest.name }}
        </div>
      </div>
      <div class="select-suggest-list-backdrop" v-if="showSuggest" @click="showSuggest = false"></div>
    </td>

    <td>
      <custom-select :options="[{label: 'بدهکار', value: 'بدهکار'},{label: 'بستانکار', value: 'بستانکار'}]"
                     :default="typeof data.status === 'string' && data.status !== '' ? {label: data.status, value: data.status} : typeof data.status === 'object' ? data.status : {label: 'بدهکار', value: 'بدهکار'}"
                     v-model="data.status"/>
    </td>

    <td class="search-box">
      <input class="get-price-input useral-custom-element-input w-full p-lg" v-model="data.price"/>
      <span class="get-price-label">{{ $locale.currency() }}</span>
    </td>

    <td class="actions-box" style="width: 50px">
      <vs-button color="danger"
                 icon="icon-x"
                 icon-pack="feather"
                 v-if="data.id"
                 @click="cancelData"/>
    </td>
  </tr>
</template>

<script>
import customSelect from '@/components/customSelect/customSelect'

export default {
  name: 'dynamicUsersInput',
  components: {
    customSelect
  },
  model: {
    prop: 'data',
    event: 'input'
  },
  props: {
    suggests: {},
    data: {}
  },
  data () {
    return {
      suggestsList: [],
      showSuggest: false
    }
  },
  mounted () {
    this.suggestsList = this.suggests
  },
  methods: {
    selectData (suggest) {
      this.data.name = suggest.name
      this.data.phone_number = suggest.phone_number
      this.data.id = suggest.id
      this.showSuggest = false
      this.$emit('item:inserted')
    },
    cancelData () {
      this.$emit('item:removed')
      this.$emit('input', {id: '', name: '', phone_number: '', status: '', price: '', show: true})
    }
  },
  watch: {
    suggests: {
      handler () {
        this.suggestsList = []
        this.suggests.forEach((suggest) => {
          this.suggestsList.push(suggest)
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>

.select-suggest-list {
  position: absolute;
  background: #212744;
  right: 5px;
  left: 5px;
  margin-top: -5px;
  padding: 0 10px;
  line-height: 35px;
  border-radius: 0 0 6px 6px;
  z-index: 10000;

  .select-suggest-item {
    border-top: 1px solid #171e3e;
    cursor: pointer;
  }
}

.select-suggest-list-backdrop {
  position: fixed;
  width: 100vw;
  height: 88vh;
  top: 0;
  left: 0;
  z-index: 10;
}

.get-price-input {
  padding-right: 50px;
  text-align: right;
}

.get-price-label {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  height: fit-content;
}
</style>
