<template>
  <div class="confirm-closed-times">
    <custom-validate-input :label="$t('closed.labels.description')"
                           align="right"
                           rtl
                           v-model="closeDescription"/>
    <div class="confirm-table">
      <draggable-dynamic-table ref="confirmClosedInvoiceList"
                               v-if="data.length"
                               :in-modal="true"
                               :active-grid="true"
                               :fix-screen="true"
                               :options="options"
                               :columns="columnsLabel"
                               @row:deleted="handleDeleteRow"
                               v-model="data"/>
    </div>

    <vs-button id="confirmClosedPriceBTN" class="useral-action-button" @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import DynamicInputTable from '@/components/dynamicInputTable/dynamicInputTable'
  import CustomValidateInput from "../../../../../components/customInput/customValidateInput.vue";
  import {insertClosedInvoice} from "../../../../../http/requests/club/closedInvoices";

  export default {
    name: 'confirmClosedTimes',
    components: {
      CustomValidateInput,
      DynamicInputTable
    },
    props: {
      selectedTimes: {},
      actionType: {}
    },
    data() {
      return {
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'delete',
            i18n: 'closed.table.header.delete',
            width: '70px',
            minWidth: 70,
            /*sortable: true,*/
            actions: true,
            showAction: 'always',
            action: {
              color: 'danger',
              icon: 'icon-x',
              iconPack: 'feather',
              type: 'button'
            },
            event: 'row:deleted'
          },
          {
            field: 'timeReserve',
            i18n: 'closed.table.header.timeReserve',
            width: '150px',
            minWidth: 150,
          },
          {
            field: 'date',
            i18n: 'closed.table.header.date',
            align: 'center',
            width: 'calc(25%)',
            minWidth: 150,
            /*sortable: true,*/
          },
          {
            field: 'name',
            i18n: 'closed.table.header.courtName',
            align: 'center',
            width: 'calc(40%)',
            minWidth: 150,
            footer: {}
          },
          {
            field: 'rowNumber',
            i18n: 'closed.table.header.row',
            align: 'center',
            width: '70px',
            minWidth: 70,
            autoIncrement: true,
            footer: {
              type: 'auto-counter'
            }
          }
        ],
        firstVisit: true,
        firstVisitTimer: 0,
        data: [],
        closeDescription: {
          value: '',
          isValid: false
        },
        selectedIndex: 0,
        total_count: 0,
        page: 0,
        filters: []
      }
    },
    created() {
      this.selectedOrders = Object.values(this.selectedTimes)
      this.getSelectedOrders()
    },
    methods: {
      getSelectedOrders() {
        const orders = this.selectedOrders
        orders.forEach((order) => {
          const data = {
            id: this.data.length + 1,
            index: this.selectedOrders.indexOf(order),
            status: 'order',
            name: `${order.court.court_name} - ${order.time.start_time.substr(0, 5)} ${this.$t('closed.labels.until')} ${order.time.end_time.substr(0, 5)} - ${order.date}`,
            courtName: order.court.court_name,
            court: order.court,
            time: order.time,
            term: order.term,
            date: order.date,
            termId: order.termId,
            timeReserve: `${order.time.start_time.substr(0, 5)} ${this.$t('closed.labels.until')} ${order.time.end_time.substr(0, 5)} `,
          }
          this.data.push(data)
        })
      },
      handleDeleteRow(row) {
        if (row && row.status === 'order') {
          const index = this.data.map((elm) => {
            return elm.id
          }).indexOf(row.id)
          if (index > -1 && row.id !== 0 && this.data.length > 1) {
            this.selectedIndex = 0
            this.data.splice(index, 1)
            this.selectedOrders.splice(index, 1)
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('closed.notifications.noTimeAfterDelete'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
          }
        }
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
      sendData() {
        let errorNum = 0

        if (this.data.length === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('closed.notifications.noTime'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
          errorNum++
          return false
        }

        if (!this.closeDescription.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('closed.notifications.descriptionWrong'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
          errorNum++
          return false
        }

        const orders = []
        this.data.forEach((order) => {
          if (order.id && order.id !== 0) {
            orders.push({
              time_id: order.time.id,
              court_id: order.court.id,
              term_id: order.termId,
              date: order.date,
            })
          }
        })

        if (errorNum === 0) {
          const data = {
            note: this.closeDescription.value,
            lines: orders,
          }

          insertClosedInvoice(data).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('closed.notifications.edit.success'),
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400
            })
            this.$router.replace({name: 'closedInvoices'})
          }).catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'user_id': '',
              }
              switch (error.response.status) {
                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('closed.notifications.edit.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        }
      },
      routeToInvoice() {
        // window.open(this.$router.resolve({name: 'print-sale-invoice-page', params:{id: this.newInvoice.id.value}}).href, '_blank')
        this.$router.replace({name: 'saleInvoices'})
      },
      handleReloadPrompt() {
        this.suggestPromptStatus = false
        setTimeout(() => {
          this.suggestPromptStatus = true
        }, 100)
      },
      handleInsertSaleAddon(row) {
      },
    },
    watch: {
      'newInvoice': {
        handler() {
          clearTimeout(this.firstVisitTimer)
          this.firstVisitTimer = setTimeout(() => {
            this.firstVisit = false
          }, 500)

          /*if (!this.firstVisit) {
            this.$store.dispatch('setPageChanges')
          }*/
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
  .confirm-closed-times {
    height: calc(100% - 15px);

    .confirm-table {
      height: calc(100% - 65px);
    }

    .draggable-dynamic-table {
      .new-suggest-modal-button {
        display: none;
      }

      .suggest-modal-button {
        right: 7px !important;
      }
    }
  }

  .action-buttons {
    display: flex;

    button {
      flex: 1;
      line-height: 40px;
      border-radius: .5rem;
      border: none;
      color: #ffffff;
      transition: all .3s ease;
      cursor: pointer;

      &.payment {
        margin-right: 10px;
        background: #33b000cf;

        &:hover {
          background: #33b000;
        }
      }

      &.cancel {
        background: #b00000af;

        &:hover {
          background: #b00000;
        }
      }
    }
  }
</style>
